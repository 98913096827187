import React from "react";
import {
  Grid,
  Paper,
  makeStyles,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import PayABillButton from "./pay-a-bill-button";
import PayOptions from "./pay-options";
import FinancialAssistance from "./financial-assistance/financial-assistance";
import ContactCard from "../../../../contact-components/contact-card/contact-card";
import { HashLink } from "react-router-hash-link";
import { ContactSupport } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  darkContent: {
    background: theme.palette.primary.main,
    color: "white",
    padding: 10,
  },
}));

export default function Billing(props) {
  const classes = useStyles();

  return (
    <div>
      {" "}
      <h2 className={"primary-title"}>Billing</h2>
      <Grid container spacing={3} style={{ marginTop: 20 }}>
        <Grid
          item
          xs={12}
          style={{
            padding: 0,
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              background: "#002b5c",
              paddingBottom: 20,
            }}
          >
            <PayABillButton />
            {/* Start of payment app error notification */}
            <div
              style={{
                width: "fit-content",
                maxWidth: "75%",
                margin: "20px auto",
                padding: 10,
                outline: "2px solid white",
                borderRadius: 10,
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                }}
              >
                <ContactSupport style={{ fontSize: 100, color: "white" }} />
                <h2 style={{ color: "white" }}>Please be Advised:</h2>
              </span>
              <hr style={{ width: "100%", color: "white" }} />
              <span
                style={{
                  color: "white",
                  fontSize: "1.2rem",
                }}
              >
                <p>
                  We are aware of an issue with our billing and payment system.
                  We are doing our best to correct the issues.
                </p>
                <p>
                  We offer sincere apologies for the confusion and inconvenience
                  that you may have experienced when receiving your monthly
                  statement.
                </p>
                <br />
                <p>
                  Please call our business office at{" "}
                  <b>
                    <a href="tel:2085494408" style={{ color: "white" }}>
                      208-549-4408 &nbsp;
                    </a>
                  </b>
                  if you have questions.
                </p>
                <br />
                <p>
                  Thank you for your understanding while we continue to work on
                  improving our billing statement processes.
                </p>
                <p>
                  We value our patients and strive to live by our mission of
                  saving lives, improving health, and building community.
                </p>
              </span>
            </div>
            {/* End of payment app error notification */}
          </div>
        </Grid>
        <Grid item sm>
          <Paper style={{ padding: 10 }}>
            <h2>Thank you for choosing Weiser Memorial Hospital.</h2>
            <Paper className={classes.darkContent}>
              <p>
                We’re dedicated first and foremost to your recovery and best
                health and we want to make your experience the best it can be —
                even after you've gone home. Our bill pay simplifies the billing
                process with convenient online tools, or we provide several easy
                pay options, putting you in control of when and how you pay.
              </p>
            </Paper>
          </Paper>
        </Grid>
        <Grid item sm>
          <Paper style={{ padding: 10 }}>
            <h2>Patient Financial Services Department</h2>
            <ButtonGroup>
              <Button
                variant="contained"
                color="primary"
                component="a"
                href={
                  process.env.PUBLIC_URL +
                  "/files/no-surprise-medical-billing-02-2022.pdf"
                }
                target={"__blank"}
              >
                No Surprise Billing
              </Button>
              <Button
                variant="outlined"
                color="primary"
                component="a"
                href={process.env.PUBLIC_URL + "/files/good-faith-message.pdf"}
                target={"__blank"}
              >
                Good Faith Est.
              </Button>
            </ButtonGroup>
            <p>
              <i>
                Questions about your bill?....Give us a call. We are here to
                meet your needs.
              </i>
            </p>
            <HashLink to={"#financial-assistance"}>
              Financial Assistance
            </HashLink>
            <ContactCard
              address={"Billing Office"}
              phone={"208-549-4408"}
              fax={"208-549-4146"}
            />
            <br />
            <ContactCard
              address={"Amber Hall, AR/ Collection Specialist"}
              phone={"208-549-4447"}
              fax={"208-414-4267"}
              email={"ahall@weiserhospital.org"}
            />
            <br />
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ background: "aliceblue", boxSizing: "initial" }}
        >
          <PayOptions />
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 10 }} className="dark-content">
            <h2>Epic River Lending</h2>
            <p>
              <i>
                Pay your medical bills without wreaking havoc on your finances
                and credit.
              </i>
            </p>
            <p>
              Unfortunately, even patients with health insurance sometimes find
              themselves with mounting medical debt. Thankfully, there is a
              solution that enables you to pay your healthcare bills without
              placing undue stress on your finances. We partnered with Epic
              River to provide a patient payment program to help you pay your
              medical bills.
              <ul>
                <li>All patients qualify, regardless of credit history.</li>
                <li>
                  You pick your monthly payment amount and the duration of the
                  loan.
                </li>
                <li>
                  All loans are fixed-rate with low-interest and no fees (hidden
                  or otherwise)
                </li>
                <li>Funded by Community Bank.</li>
                <li>
                  Paying off medical loans is a great way to improve your
                  credit.
                </li>
              </ul>
              <br />
            </p>

            <br />
            <ButtonGroup>
              <Button
                variant="contained"
                color="primary"
                component="a"
                href="https://epicriverhealth.com/wmh"
                target={"__blank"}
              >
                Learn More
              </Button>
              {/* <Button
                variant="outlined"
                color="primary"
                component="a"
                href={process.env.PUBLIC_URL + "/files/good-faith-message.pdf"}
                target={"__blank"}
              >
                Good Faith Est.
              </Button> */}
            </ButtonGroup>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <FinancialAssistance />
        </Grid>
      </Grid>
    </div>
  );
}
