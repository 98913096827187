import { Grid } from "@material-ui/core";
import React from "react";
import BoardMembers from "../../landing-page-components/foundation-section/board-members/board-member";
import family from "../../../../../assets/imgs/portrait/familyphoto1.jpg";
import FoundationContact from "../../landing-page-components/foundation-section/foundation-contact/foundation-contact";

export default function TheFoundation() {
  return (
    <div>
      <h2 className={"primary-title"}>Weiser Memorial Hospital Foundation</h2>
      <p>
        The Weiser Memorial Hospital Foundation was founded in 1988 as a
        citizen-driven effort to develop resources for Weiser Memorial Hospital.
        In 2000, the Foundation reorganized, incorporated and received 501c3
        designation from the IRS. Since then the Foundation has secured more
        than $5 million through fundraising and grants to update the hospital’s
        medical equipment and building. The Foundation organizes and hosts one
        of the community’s largest events, the Festival of Trees. This
        long-standing, week long community event involves more than 300
        volunteers and raises approximately over $100,000 annually.
      </p>
      <Grid container alignItems={"center"} spacing={3}>
        <Grid item md={6} style={{ margin: "0 auto" }}>
          <img src={family} alt={"family"} style={{ maxWidth: "100%" }} />
          <FoundationContact />
        </Grid>
        <Grid item md={6}>
          <BoardMembers />
        </Grid>
      </Grid>
    </div>
  );
}
